var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
// v1.0.0
!function (e, t) {
  exports = t();
}(exports, function () {
  "use strict";

  var e = window,
      t = e.BlobBuilder || e.WebKitBlobBuilder || e.MozBlobBuilder || e.MSBlobBuilder,
      r = e.URL || e.webkitURL || e.mozURL || e.msURL,
      n = "application/javascript",
      a = "undefined" == typeof Symbol ? "__t" + +new Date() : Symbol(),
      i = e.Worker,
      o = e.setImmediate || function (e) {
    return setTimeout(e, 1);
  };

  function s(e, t) {
    var r = Object.create(null);
    return e.onmessage = null, e.addEventListener = function (e, t) {
      var n = r[e] || (r[e] = []);
      ~n.indexOf(t) || n.push(t);
    }, e.removeEventListener = function (e, t) {
      var n,
          a = r[e];
      a && -1 !== (n = a.indexOf(t)) && (a.splice(n, 1), a.length || delete r[e]);
    }, e.postMessage = function (r) {
      o(function () {
        var n = r;
        if (t.onmessage) try {
          t.onmessage({
            data: n,
            target: e
          });
        } catch (e) {
          console.error(e);
        }
        t.emit("message", {
          type: "message",
          data: n,
          target: e,
          timeStamp: +new Date()
        });
      });
    }, e.emit = function (t, n) {
      var a = r[t];
      a && a.forEach(function (t, r) {
        return t.call(e, n);
      });
    }, e.destroy = function () {
      Object.keys(r).forEach(function (e) {
        var t = r[e];
        t && (t.length = 0, delete r[e]);
      }), r = null;
    }, e;
  }

  if (i) {
    var f,
        u = v("self.onmessage = function () {}"),
        c = new Uint8Array(1);

    try {
      if (/(?:Trident|Edge)\/(?:[567]|12)/i.test(navigator.userAgent)) throw new Error("Not available");
      (f = new i(u)).postMessage(c, [c.buffer]);
    } catch (l) {
      i = null;
    } finally {
      r.revokeObjectURL(u), f && f.terminate();
    }
  }

  function v(e) {
    var a = n;

    try {
      return r.createObjectURL(new Blob([e], {
        type: a
      }));
    } catch (n) {
      var i = new t();
      return i.append(e), r.createObjectURL(i.getBlob(a));
    }
  }

  var l,
      h,
      d = (l = "worker#./worker", h = function () {
    return function (e, t) {
      var r = 0.5 * (Math.sqrt(3) - 1),
          n = (3 - Math.sqrt(3)) / 6,
          a = 1 / 6,
          i = (Math.sqrt(5) - 1) / 4,
          o = (5 - Math.sqrt(5)) / 20;

      function s(e) {
        var t;
        t = "function" == typeof e ? e : e ? function () {
          var e,
              t = 0,
              r = 0,
              n = 0,
              a = 1,
              i = (e = 4022871197, function (t) {
            t = t.toString();

            for (var r = 0; r < t.length; r++) {
              var n = 0.02519603282416938 * (e += t.charCodeAt(r));
              n -= e = n >>> 0, e = (n *= e) >>> 0, e += 4294967296 * (n -= e);
            }

            return 2.3283064365386963e-10 * (e >>> 0);
          });
          t = i(" "), r = i(" "), n = i(" ");

          for (var o = 0; o < arguments.length; o++) (t -= i(arguments[o])) < 0 && (t += 1), (r -= i(arguments[o])) < 0 && (r += 1), (n -= i(arguments[o])) < 0 && (n += 1);

          return i = null, function () {
            var e = 2091639 * t + 2.3283064365386963e-10 * a;
            return t = r, r = n, n = e - (a = 0 | e);
          };
        }(e) : Math.random, (this || _global).p = f(t), (this || _global).perm = new Uint8Array(512), (this || _global).permMod12 = new Uint8Array(512);

        for (var r = 0; r < 512; r++) (this || _global).perm[r] = (this || _global).p[255 & r], (this || _global).permMod12[r] = (this || _global).perm[r] % 12;
      }

      function f(e) {
        var t,
            r = new Uint8Array(256);

        for (t = 0; t < 256; t++) r[t] = t;

        for (t = 0; t < 255; t++) {
          var n = t + ~~(e() * (256 - t)),
              a = r[t];
          r[t] = r[n], r[n] = a;
        }

        return r;
      }

      function u() {
        return function (e) {
          var t = 0,
              r = 0,
              n = 0,
              a = 1;
          0 == e.length && (e = [+new Date()]);

          var i = function () {
            var e = 4022871197,
                t = function (t) {
              t = t.toString();

              for (var r = 0; r < t.length; r++) {
                var n = 0.02519603282416938 * (e += t.charCodeAt(r));
                n -= e = n >>> 0, e = (n *= e) >>> 0, e += 4294967296 * (n -= e);
              }

              return 2.3283064365386963e-10 * (e >>> 0);
            };

            return t.version = "Mash 0.9", t;
          }();

          t = i(" "), r = i(" "), n = i(" ");

          for (var o = 0; o < e.length; o++) (t -= i(e[o])) < 0 && (t += 1), (r -= i(e[o])) < 0 && (r += 1), (n -= i(e[o])) < 0 && (n += 1);

          i = null;

          var s = function () {
            var e = 2091639 * t + 2.3283064365386963e-10 * a;
            return t = r, r = n, n = e - (a = 0 | e);
          };

          return s.uint32 = function () {
            return 4294967296 * s();
          }, s.fract53 = function () {
            return s() + 1.1102230246251565e-16 * (2097152 * s() | 0);
          }, s.version = "Alea 0.9", s.args = e, s.exportState = function () {
            return [t, r, n, a];
          }, s.importState = function (e) {
            t = +e[0] || 0, r = +e[1] || 0, n = +e[2] || 0, a = +e[3] || 0;
          }, s;
        }(Array.prototype.slice.call(arguments));
      }

      s.prototype = {
        grad3: new Float32Array([1, 1, 0, -1, 1, 0, 1, -1, 0, -1, -1, 0, 1, 0, 1, -1, 0, 1, 1, 0, -1, -1, 0, -1, 0, 1, 1, 0, -1, 1, 0, 1, -1, 0, -1, -1]),
        grad4: new Float32Array([0, 1, 1, 1, 0, 1, 1, -1, 0, 1, -1, 1, 0, 1, -1, -1, 0, -1, 1, 1, 0, -1, 1, -1, 0, -1, -1, 1, 0, -1, -1, -1, 1, 0, 1, 1, 1, 0, 1, -1, 1, 0, -1, 1, 1, 0, -1, -1, -1, 0, 1, 1, -1, 0, 1, -1, -1, 0, -1, 1, -1, 0, -1, -1, 1, 1, 0, 1, 1, 1, 0, -1, 1, -1, 0, 1, 1, -1, 0, -1, -1, 1, 0, 1, -1, 1, 0, -1, -1, -1, 0, 1, -1, -1, 0, -1, 1, 1, 1, 0, 1, 1, -1, 0, 1, -1, 1, 0, 1, -1, -1, 0, -1, 1, 1, 0, -1, 1, -1, 0, -1, -1, 1, 0, -1, -1, -1, 0]),
        noise2D: function (e, t) {
          var a,
              i,
              o = (this || _global).permMod12,
              s = (this || _global).perm,
              f = (this || _global).grad3,
              u = 0,
              c = 0,
              v = 0,
              l = (e + t) * r,
              h = Math.floor(e + l),
              d = Math.floor(t + l),
              g = (h + d) * n,
              m = e - (h - g),
              p = t - (d - g);
          m > p ? (a = 1, i = 0) : (a = 0, i = 1);
          var w = m - a + n,
              y = p - i + n,
              M = m - 1 + 2 * n,
              b = p - 1 + 2 * n,
              D = 255 & h,
              L = 255 & d,
              U = 0.5 - m * m - p * p;

          if (U >= 0) {
            var x = 3 * o[D + s[L]];
            u = (U *= U) * U * (f[x] * m + f[x + 1] * p);
          }

          var A = 0.5 - w * w - y * y;

          if (A >= 0) {
            var S = 3 * o[D + a + s[L + i]];
            c = (A *= A) * A * (f[S] * w + f[S + 1] * y);
          }

          var B = 0.5 - M * M - b * b;

          if (B >= 0) {
            var j = 3 * o[D + 1 + s[L + 1]];
            v = (B *= B) * B * (f[j] * M + f[j + 1] * b);
          }

          return 70 * (u + c + v);
        },
        noise3D: function (e, t, r) {
          var n,
              i,
              o,
              s,
              f,
              u,
              c,
              v,
              l,
              h,
              d = (this || _global).permMod12,
              g = (this || _global).perm,
              m = (this || _global).grad3,
              p = (e + t + r) * (1 / 3),
              w = Math.floor(e + p),
              y = Math.floor(t + p),
              M = Math.floor(r + p),
              b = (w + y + M) * a,
              D = e - (w - b),
              L = t - (y - b),
              U = r - (M - b);
          D >= L ? L >= U ? (f = 1, u = 0, c = 0, v = 1, l = 1, h = 0) : D >= U ? (f = 1, u = 0, c = 0, v = 1, l = 0, h = 1) : (f = 0, u = 0, c = 1, v = 1, l = 0, h = 1) : L < U ? (f = 0, u = 0, c = 1, v = 0, l = 1, h = 1) : D < U ? (f = 0, u = 1, c = 0, v = 0, l = 1, h = 1) : (f = 0, u = 1, c = 0, v = 1, l = 1, h = 0);
          var x = D - f + a,
              A = L - u + a,
              S = U - c + a,
              B = D - v + 2 * a,
              j = L - l + 2 * a,
              O = U - h + 2 * a,
              E = D - 1 + 0.5,
              R = L - 1 + 0.5,
              k = U - 1 + 0.5,
              T = 255 & w,
              q = 255 & y,
              C = 255 & M,
              I = 0.6 - D * D - L * L - U * U;
          if (I < 0) n = 0;else {
            var _ = 3 * d[T + g[q + g[C]]];

            n = (I *= I) * I * (m[_] * D + m[_ + 1] * L + m[_ + 2] * U);
          }
          var z = 0.6 - x * x - A * A - S * S;
          if (z < 0) i = 0;else {
            var F = 3 * d[T + f + g[q + u + g[C + c]]];
            i = (z *= z) * z * (m[F] * x + m[F + 1] * A + m[F + 2] * S);
          }
          var N = 0.6 - B * B - j * j - O * O;
          if (N < 0) o = 0;else {
            var P = 3 * d[T + v + g[q + l + g[C + h]]];
            o = (N *= N) * N * (m[P] * B + m[P + 1] * j + m[P + 2] * O);
          }
          var W = 0.6 - E * E - R * R - k * k;
          if (W < 0) s = 0;else {
            var K = 3 * d[T + 1 + g[q + 1 + g[C + 1]]];
            s = (W *= W) * W * (m[K] * E + m[K + 1] * R + m[K + 2] * k);
          }
          return 32 * (n + i + o + s);
        },
        noise4D: function (e, t, r, n) {
          var a,
              s,
              f,
              u,
              c,
              v,
              l,
              h,
              d,
              g,
              m,
              p,
              w,
              y,
              M,
              b,
              D,
              L = (this || _global).perm,
              U = (this || _global).grad4,
              x = (e + t + r + n) * i,
              A = Math.floor(e + x),
              S = Math.floor(t + x),
              B = Math.floor(r + x),
              j = Math.floor(n + x),
              O = (A + S + B + j) * o,
              E = e - (A - O),
              R = t - (S - O),
              k = r - (B - O),
              T = n - (j - O),
              q = 0,
              C = 0,
              I = 0,
              _ = 0;
          E > R ? q++ : C++, E > k ? q++ : I++, E > T ? q++ : _++, R > k ? C++ : I++, R > T ? C++ : _++, k > T ? I++ : _++;
          var z = E - (v = q >= 3 ? 1 : 0) + o,
              F = R - (l = C >= 3 ? 1 : 0) + o,
              N = k - (h = I >= 3 ? 1 : 0) + o,
              P = T - (d = _ >= 3 ? 1 : 0) + o,
              W = E - (g = q >= 2 ? 1 : 0) + 2 * o,
              K = R - (m = C >= 2 ? 1 : 0) + 2 * o,
              G = k - (p = I >= 2 ? 1 : 0) + 2 * o,
              H = T - (w = _ >= 2 ? 1 : 0) + 2 * o,
              J = E - (y = q >= 1 ? 1 : 0) + 3 * o,
              Q = R - (M = C >= 1 ? 1 : 0) + 3 * o,
              V = k - (b = I >= 1 ? 1 : 0) + 3 * o,
              X = T - (D = _ >= 1 ? 1 : 0) + 3 * o,
              Y = E - 1 + 4 * o,
              Z = R - 1 + 4 * o,
              $ = k - 1 + 4 * o,
              ee = T - 1 + 4 * o,
              te = 255 & A,
              re = 255 & S,
              ne = 255 & B,
              ae = 255 & j,
              ie = 0.6 - E * E - R * R - k * k - T * T;
          if (ie < 0) a = 0;else {
            var oe = L[te + L[re + L[ne + L[ae]]]] % 32 * 4;
            a = (ie *= ie) * ie * (U[oe] * E + U[oe + 1] * R + U[oe + 2] * k + U[oe + 3] * T);
          }
          var se = 0.6 - z * z - F * F - N * N - P * P;
          if (se < 0) s = 0;else {
            var fe = L[te + v + L[re + l + L[ne + h + L[ae + d]]]] % 32 * 4;
            s = (se *= se) * se * (U[fe] * z + U[fe + 1] * F + U[fe + 2] * N + U[fe + 3] * P);
          }
          var ue = 0.6 - W * W - K * K - G * G - H * H;
          if (ue < 0) f = 0;else {
            var ce = L[te + g + L[re + m + L[ne + p + L[ae + w]]]] % 32 * 4;
            f = (ue *= ue) * ue * (U[ce] * W + U[ce + 1] * K + U[ce + 2] * G + U[ce + 3] * H);
          }
          var ve = 0.6 - J * J - Q * Q - V * V - X * X;
          if (ve < 0) u = 0;else {
            var le = L[te + y + L[re + M + L[ne + b + L[ae + D]]]] % 32 * 4;
            u = (ve *= ve) * ve * (U[le] * J + U[le + 1] * Q + U[le + 2] * V + U[le + 3] * X);
          }
          var he = 0.6 - Y * Y - Z * Z - $ * $ - ee * ee;
          if (he < 0) c = 0;else {
            var de = L[te + 1 + L[re + 1 + L[ne + 1 + L[ae + 1]]]] % 32 * 4;
            c = (he *= he) * he * (U[de] * Y + U[de + 1] * Z + U[de + 2] * $ + U[de + 3] * ee);
          }
          return 27 * (a + s + f + u + c);
        }
      }, s._buildPermutationTable = f, u.importState = function (e) {
        var t = new u();
        return t.importState(e), t;
      }, e.exports = function (e) {
        e.addEventListener("message", function (t) {
          for (var r = t.data, n = r.imageData, a = r.intensity, i = void 0 === a ? 0.04 : a, o = {
            x: 0,
            y: 0,
            width: n.width,
            height: n.height
          }, f = new s(new u()), c = n.data, v = n.width, l = n.height, h = o.x, d = o.y, g = Math.min(o.width, o.height), m = 0; m < l; m++) for (var p = 0; p < v; p++) {
            var w = 4 * (m * v + p),
                y = (c[w] + c[w + 1] + c[w + 2]) / 768 - 0.5,
                M = p + h,
                b = m + d,
                D = 0.5 * (f.noise2D(M / g * 800, b / g * 800) + 0.25 * f.noise2D(M / g * 800 / 2, b / g * 800 / 2) + f.noise2D(M / g * 800 / 4, b / g * 800 / 4));
            D *= 1 - y * y * 2, D *= 255 * i, n.data[w] = c[w] + D, n.data[w + 1] = c[w + 1] + D, n.data[w + 2] = c[w + 2] + D;
          }

          postMessage(n), e.close();
        });
      };
    }(e = {
      exports: {}
    }), e.exports;
    var e;
  }, function e(t) {
    var n = this || _global;
    if (!(n instanceof e)) return new e(t);
    if (!h) return new i(l);

    if (i && !t) {
      var o = v(";(function(f){f&&new(f.default?f[\"default\"]:f)(self)}((" + h.toString() + ")()))"),
          f = new i(o);
      return r.revokeObjectURL(o), n[a] = f;
    }

    var u = new s({
      close: function () {
        this.destroy();
      }
    }, n);
    Object.assign(new s(n, u), {
      isThisThread: !0,
      terminate: function () {
        u.close(), this.destroy();
      }
    }), h().call(u, u);
  });
  return function (e) {
    var t = e.canvas,
        r = e.intensity;
    return new Promise(function (e) {
      var n = t.getContext("2d"),
          a = n.getImageData(0, 0, t.width, t.height),
          i = new d();
      i.postMessage({
        imageData: a,
        intensity: r
      }), i.addEventListener("message", function (t) {
        var r = t.data;
        n.putImageData(r, 0, 0), e();
      });
    });
  };
});
export default exports;